import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ApiProvider } from './hooks/api';
import { UserProvider } from './hooks/user';
import { ImagesProvider } from './hooks/images';
import { DeviceProvider } from './hooks/device';
import { CompanyProvider } from './hooks/company';
import { FeatureFlagsProvider } from './hooks/featureFlags';
import Root from './routes/Root';
import Cart from './routes/Cart';
import Orders from './routes/Orders';
import Contact from './routes/Contact';
import Desktop from './routes/Desktop';
import Invoices from './routes/Invoices';
import Vignettes from './routes/Vignettes';
import FleetStatus from './routes/FleetStatus';
import FleetVehicles from './routes/FleetVehicles';

const nodeEnv = process.env.NODE_ENV;

const id = 'company-viewer';

document.addEventListener('turbolinks:load', async () => {
  if (nodeEnv !== 'development') {
    // Handle all legacy hostnames and redirect to app.flotomat.pl
    redirectToAppFlotomat();
  }

  const element = document.getElementById(id);
  if (!element) {
    return;
  }

  const customer = getCustomer();
  const images = getImages();
  const featureFlags = getFeatureFlags();

  const { taxIdentificationHash } = customer;

  const router = createBrowserRouter([
    {
      path: '/firma/:taxIdentificationHash',
      element: <Root />,
      children: [
        { index: true, element: <Desktop /> },
        {
          path: 'koszyk',
          element: <Cart />,
        },
        {
          path: 'flota/ustawienia',
          element: <FleetVehicles />,
        },
        {
          path: 'flota/pojazdy',
          element: <FleetStatus />,
        },
        {
          path: 'winiety',
          element: <Vignettes />,
        },
        {
          path: 'zamowienia',
          element: <Orders />,
        },
        {
          path: 'faktury',
          element: <Invoices />,
        },
        {
          path: 'kontakt',
          element: <Contact />,
        },
      ],
    },
  ]);

  let company;
  let user;
  try {
    [company, user] = await Promise.all([
      getCompany(taxIdentificationHash),
      getUser(taxIdentificationHash),
    ]);
  } catch (e) {
    window.location.href = `/firma/${taxIdentificationHash}/blad-inicjalizacji?error=${encodeURIComponent(
      e.message
    )}`;
    return;
  }

  ReactDOM.createRoot(element).render(
    <CompanyProvider company={company}>
      <UserProvider user={user}>
        <ImagesProvider images={images}>
          <FeatureFlagsProvider featureFlags={featureFlags}>
            <ApiProvider>
              <DeviceProvider>
                <RouterProvider router={router} />
              </DeviceProvider>
            </ApiProvider>
          </FeatureFlagsProvider>
        </ImagesProvider>
      </UserProvider>
    </CompanyProvider>
  );
});

function redirectToAppFlotomat() {
  const locationHref = window.location.href;
  const url = new URL(locationHref);
  if (!['app.flotomat.pl', 'www.app.flotomat.pl'].includes(url.hostname)) {
    window.location.href = `https://app.flotomat.pl${url.pathname}${url.search}`;
  }
}

function getCustomer() {
  const customerData = document.getElementById('customer-data');
  if (!customerData) {
    throw new Error('Customer data was not provided!');
  }

  const {
    dataset: { taxIdentificationHash, taxIdentificationNumber },
  } = customerData;
  if (!taxIdentificationHash) {
    throw new Error('Tax identification hash was not provided!');
  }
  if (!taxIdentificationNumber) {
    throw new Error('Tax identification number was not provided!');
  }

  return { taxIdentificationHash, taxIdentificationNumber };
}

function getImages() {
  const imagesData = document.getElementById('images-data');
  if (!imagesData) {
    throw new Error('Images data was not provided!');
  }

  const { dataset: imagesDataset } = imagesData;
  if (!imagesDataset) {
    throw new Error('Images were not provided!');
  }
  const images = {
    registrationCountries: JSON.parse(imagesDataset.registrationCountries),
  };

  return images;
}

function getFeatureFlags() {
  const featureFlagsData = document.getElementById('feature-flags-data');
  if (!featureFlagsData) {
    throw new Error('Feature flags data was not provided!');
  }

  const { dataset: featureFlagsDataset } = featureFlagsData;
  if (!featureFlagsDataset) {
    throw new Error('Feature flags were not provided!');
  }

  let vehiclesSettings = false;
  try {
    vehiclesSettings = JSON.parse(featureFlagsDataset.vehiclesSettings);
  } catch (e) {
    console.error('Could not parse vehiclesSettings!');
    console.error(e);
  }
  const featureFlags = {
    vehiclesSettings,
  };

  return featureFlags;
}

async function getCompany(taxIdentificationHash) {
  const response = await fetch(`/api/fleet/${taxIdentificationHash}/company`);
  if (response.status === 200) {
    const data = await response.json();
    return {
      taxIdentificationHash: data.tax_identification_hash,
      taxIdentificationNumber: data.tax_identification_number,
      name: data.name,
      address: data.address,
      postcode: data.postcode,
      city: data.city,
      regon: data.regon,
      billedMonthly: data.billed_monthly,
    };
  }

  throw new Error('NO_COMPANY');
}

async function getUser(taxIdentificationHash) {
  const response = await fetch(`/api/fleet/${taxIdentificationHash}/user`);
  if (response.status === 200) {
    const data = await response.json();
    if (data) {
      return {
        id: data.id,
        email: data.email,
        firstName: data.first_name,
        mobile: data.mobile,
        logout: () => {
          window.location.href = `/firma/${taxIdentificationHash}/wyloguj-sie`;
        },
      };
    }

    return null;
  }

  throw new Error('NO_USER');
}
