import React, { useState, useEffect } from 'react';
import { plPL } from '@mui/x-date-pickers/locales';
import { Outlet, useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import { useClient } from '../hooks/api';
import { useDevice } from '../hooks/device';
import { useCompany } from '../hooks/company';
import { SnackbarProvider } from '../hooks/snackbar';
import UnexpectedError from '../components/UnexpectedError';
import MobileNavigator from '../components/MobileNavigator';
import DesktopNavigator from '../components/DesktopNavigator';
import UserRegisterPrompter from '../components/UserRegisterPrompter';
import './Root.scss';

const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#046eb9',
    },
    secondary: {
      main: '#f50057',
    },
    custom: {
      background: '#e5e5e5',
      warningBackgroundColor: '#fff59d',
      warningColor: '#ef6c00',
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgb(223, 241, 255)',
          },
        },
      },
    },
  },
};

const theme = createTheme(themeOptions, plPL);

export default function Root() {
  const location = useLocation();
  const client = useClient();
  const company = useCompany();
  const { isBrowser } = useDevice();

  const [unauthorizedError, setUnauthorizedError] = useState(false);
  const [unexpectedError, setUnexpectedError] = useState(false);
  const [interceptorsReady, setInterceptorsReady] = useState(false);

  useEffect(() => {
    client.company.interceptors.request.use(
      (config) => {
        setUnauthorizedError(false);
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    client.company.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          setUnauthorizedError(true);
          return;
        }

        if (error.response.status === 500) {
          setUnexpectedError(true);
          return;
        }

        throw error;
      }
    );

    setInterceptorsReady(true);
  }, []);

  useEffect(() => {
    setUnauthorizedError(false);
    setUnexpectedError(false);
  }, [location]);

  const renderContent = () => {
    if (!interceptorsReady) {
      return null;
    }

    if (unauthorizedError) {
      return <UserRegisterPrompter />;
    }

    if (unexpectedError) {
      return <UnexpectedError />;
    }

    return <Outlet />;
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <Box sx={{ display: 'flex', padding: 0 }}>
          <CssBaseline />
          {isBrowser ? (
            <DesktopNavigator
              taxIdentificationHash={company.taxIdentificationHash}
            />
          ) : (
            <MobileNavigator
              taxIdentificationHash={company.taxIdentificationHash}
            />
          )}
          <Box
            component='main'
            sx={{
              p: 0,
              width: '100%',
              backgroundColor: theme.palette.custom.background,
            }}
          >
            <Container
              maxWidth={false}
              style={{
                ...(isBrowser ? null : { paddingTop: '60px' }),
                ...(isBrowser
                  ? { paddingLeft: '16px', paddingRight: '16px' }
                  : { paddingLeft: '8px', paddingRight: '8px' }),
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
              }}
            >
              {renderContent()}
              <Box variant='outlined' sx={{ m: 2 }} align='center'>
                <Typography
                  style={{
                    color: '#757575',
                    fontSize: 14,
                    paddingLeft: 16,
                    marginTop: 8,
                    marginBottom: 8,
                  }}
                >
                  &copy; {new Date().getFullYear()} Flotomat.pl |{' '}
                  <a
                    href='https://app.flotomat.pl/regulamin'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Regulamin
                  </a>{' '}
                  |{' '}
                  <a
                    href='https://app.flotomat.pl/polityka-prywatnosci'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Polityka prywatności
                  </a>{' '}
                  |{' '}
                  <a
                    href='https://app.flotomat.pl/opis-zagrozen'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Opis zagrożeń
                  </a>
                </Typography>
              </Box>
            </Container>
          </Box>
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
