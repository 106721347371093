import createApi from './api';

export default (client) => {
  const api = createApi(client);

  return {
    async getMessages(taxIdentificationHash) {
      const { data } = await api.getMessages(taxIdentificationHash);
      return data;
    },
    async getVehiclesWithoutVignettes(taxIdentificationHash) {
      const { data } = await api.getVehiclesWithoutVignettes(
        taxIdentificationHash
      );
      return data;
    },
    async getVignettes(taxIdentificationHash) {
      const { data } = await api.getVignettes(taxIdentificationHash);
      return data;
    },
    async getExpiringVignettes(taxIdentificationHash) {
      const { data } = await api.getExpiringVignettes(taxIdentificationHash);
      return data;
    },
    async getVignette(taxIdentificationHash, vignetteId) {
      const { data } = await api.getVignette(taxIdentificationHash, vignetteId);
      return data;
    },
    async getInvoices(taxIdentificationHash) {
      const { data } = await api.getInvoices(taxIdentificationHash);
      return data;
    },
    async getInvoice(taxIdentificationHash, invoiceId) {
      const response = await api.getInvoice(taxIdentificationHash, invoiceId);
      const file = new Blob([response.data], { type: 'application/pdf' });
      return file;
    },
    async getVehicles(taxIdentificationHash) {
      const { data } = await api.getVehicles(taxIdentificationHash);
      return data;
    },
    async createVehicle(taxIdentificationHash, vehicleSettings) {
      const response = await api.createVehicle(
        taxIdentificationHash,
        vehicleSettings
      );
      return response;
    },
    async deleteVehicle(taxIdentificationHash, vehicleId) {
      const response = await api.deleteVehicle(
        taxIdentificationHash,
        vehicleId
      );
      return response;
    },
    async updateVehicle(taxIdentificationHash, vehicleId, vehicleSettings) {
      const response = await api.updateVehicle(
        taxIdentificationHash,
        vehicleId,
        vehicleSettings
      );
      return response;
    },
    async getStatus(taxIdentificationHash) {
      const { data } = await api.getStatus(taxIdentificationHash);
      return data;
    },
    async getCart(taxIdentificationHash) {
      const { data } = await api.getCart(taxIdentificationHash);
      return data;
    },
    async addCartItem(taxIdentificationHash, cartItem) {
      const response = await api.addCartItem(taxIdentificationHash, cartItem);
      return response;
    },
    async removeCartItem(taxIdentificationHash, cartItemId) {
      const response = await api.removeCartItem(
        taxIdentificationHash,
        cartItemId
      );
      return response;
    },
    async getOrders(taxIdentificationHash) {
      const { data } = await api.getOrders(taxIdentificationHash);
      return data;
    },
    async createOrder(taxIdentificationHash) {
      const response = await api.createOrder(taxIdentificationHash);
      return response;
    },
    async createOrderAp(taxIdentificationHash) {
      const response = await api.createOrderAp(taxIdentificationHash);
      return response;
    },
    async getProducts(taxIdentificationHash) {
      const { data } = await api.getProducts(taxIdentificationHash);
      return data;
    },
    async createExternalProduct(taxIdentificationHash, externalProduct) {
      const response = await api.createExternalProduct(taxIdentificationHash, externalProduct);
      return response;
    },
  };
};
