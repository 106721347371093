export default (client) => ({
  async getMessages(taxIdentificationHash) {
    return client.get(`api/fleet/${taxIdentificationHash}/messages`);
  },
  async getVignettes(taxIdentificationHash) {
    return client.get(`api/fleet/${taxIdentificationHash}/vignettes`);
  },
  async getExpiringVignettes(taxIdentificationHash) {
    return client.get(`api/fleet/${taxIdentificationHash}/vignettes/expiring`);
  },
  async getVignette(taxIdentificationHash, vignetteId) {
    return client.get(
      `api/fleet/${taxIdentificationHash}/vignettes/${vignetteId}`
    );
  },
  async getVehiclesWithoutVignettes(taxIdentificationHash) {
    return client.get(
      `api/fleet/${taxIdentificationHash}/vehicles/without-vignettes`
    );
  },
  async getVehicles(taxIdentificationHash) {
    return client.get(`api/fleet/${taxIdentificationHash}/vehicles`);
  },
  async createVehicle(taxIdentificationHash, vehicleSettings) {
    return client.post(
      `api/fleet/${taxIdentificationHash}/vehicles`,
      vehicleSettings
    );
  },
  async deleteVehicle(taxIdentificationHash, vehicleId) {
    return client.delete(
      `api/fleet/${taxIdentificationHash}/vehicles/${vehicleId}`
    );
  },
  async updateVehicle(taxIdentificationHash, vehicleId, vehicleSettings) {
    return client.put(
      `api/fleet/${taxIdentificationHash}/vehicles/${vehicleId}`,
      vehicleSettings
    );
  },
  async getStatus(taxIdentificationHash) {
    return client.get(`api/fleet/${taxIdentificationHash}/status`);
  },
  async getInvoices(taxIdentificationHash) {
    return client.get(`api/fleet/${taxIdentificationHash}/invoices`);
  },
  async getInvoice(taxIdentificationHash, invoiceId) {
    return client.get(
      `api/fleet/${taxIdentificationHash}/invoices/${invoiceId}`,
      { responseType: 'blob' }
    );
  },
  async getCart(taxIdentificationHash) {
    return client.get(`api/fleet/${taxIdentificationHash}/carts`);
  },
  async addCartItem(taxIdentificationHash, cartItem) {
    return client.post(
      `api/fleet/${taxIdentificationHash}/cart_items`,
      cartItem
    );
  },
  async removeCartItem(taxIdentificationHash, cartItemId) {
    return client.delete(
      `api/fleet/${taxIdentificationHash}/cart_items/${cartItemId}`
    );
  },
  async getOrders(taxIdentificationHash) {
    return client.get(`api/fleet/${taxIdentificationHash}/orders`);
  },
  async createOrder(taxIdentificationHash) {
    return client.post(`api/fleet/${taxIdentificationHash}/orders`);
  },
  async createOrderAp(taxIdentificationHash) {
    return client.post(`api/fleet/${taxIdentificationHash}/orders-ap`);
  },
  async getProducts(taxIdentificationHash) {
    return client.get(`api/fleet/${taxIdentificationHash}/products`);
  },
  async createExternalProduct(taxIdentificationHash, externalProduct) {
    return client.post(
      `api/fleet/${taxIdentificationHash}/external_products`,
      externalProduct
    );
  },
});
