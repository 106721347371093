/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { pl } from 'date-fns/locale/pl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useStatusStateValue } from '../../../contexts/statusStateContext';
import Plate from '../../../components/Plate';
import Flag from '../../../components/Flag/Flag';
import FleetButton from '../../../components/FleetButton';
import { useCompany } from '../../../hooks/company';
import { useRepository } from '../../../hooks/api';
import { useSnackbar } from '../../../hooks/snackbar';
import { displayPrice } from '../../../shared/numbers';
import { COUNTRY_LABEL_TO_COUNTRY_CODE } from '../../../shared/constants';
import './AddVignetteDialog.scss';

export default function AddVignetteDialog({ onClose }) {
  const company = useCompany();
  const repository = useRepository();
  const openSnackbar = useSnackbar();

  const [state, dispatch] = useStatusStateValue();

  const handleVignetteCountryChange = (event) => {
    const countryCode = event.target.value;
    dispatch({
      type: 'SET_VIGNETTE_PARAMS',
      payload: {
        countryCode,
      },
    });
    dispatch({
      type: 'SET_AVAILABLE_VIGNETTES',
      payload: countryCode,
    });
    dispatch({
      type: 'SET_FIRST_AVAILABLE_VIGNETTE',
    });
  };

  const handleVignetteTypeChange = (event) => {
    const vignette = event.target.value;
    const [countryCode, type, period] = vignette.split('-');
    dispatch({
      type: 'SET_VIGNETTE_PARAMS',
      payload: {
        countryCode,
        type,
        period,
      },
    });
  };

  const handleStartDateValueChange = (startDate) => {
    dispatch({
      type: 'SET_VIGNETTE_PARAMS',
      payload: {
        startDate,
      },
    });
  };

  const handleStartDateErrorChange = (error) => {
    const isVignetteStartDateOK = error === null;
    dispatch({
      type: 'SET_IS_VIGNETTE_START_DATE_OK',
      payload: isVignetteStartDateOK,
    });
  };

  const handleSubmitButton = async () => {
    const {
      vehicle,
      vignette: { countryCode, type, period, startDate },
    } = state;

    const vignetteCode = `${countryCode}-${type}-${period}`;
    const vignetteStartDate = startDate.toISOString().slice(0, 10);
    const vehicleCountryCode =
      COUNTRY_LABEL_TO_COUNTRY_CODE[vehicle.registrationCountry];

    const cartItem = {
      vignette_code: vignetteCode,
      vignette_start_date: vignetteStartDate,
      vehicle_country_code: vehicleCountryCode,
      vehicle_license_plate: vehicle.registrationNumber,
      vehicle_vin_number: vehicle.vinNumber,
    };

    try {
      const response = await repository.company.addCartItem(
        company.taxIdentificationHash,
        cartItem
      );

      onClose();
      const { status } = response;
      if (status === 201) {
        openSnackbar(
          <>
            Dodano winietę do koszyka.{' '}
            <Link
              underline='hover'
              component={RouterLink}
              to={`/firma/${company.taxIdentificationHash}/koszyk`}
            >
              Przejdź do koszyka
            </Link>
            .
          </>,
          'success'
        );
      }
    } catch (e) {
      const { response } = e;
      if (response.status === 422) {
        openSnackbar('Nie udało się dodać winiety do koszyka', 'error');
      } else {
        openSnackbar('Nie udało się dodać winiety do koszyka', 'error');
      }
    }
  };

  const isSubmitButtonDisabled = useMemo(() => {
    const { availableVignettes, isVignetteStartDateOK } = state;

    if (!availableVignettes || availableVignettes.length === 0) {
      return true;
    }

    if (!isVignetteStartDateOK) {
      return true;
    }

    const {
      vignette: { countryCode },
      vehicle,
    } = state;
    if (
      countryCode === 'RO' &&
      (vehicle.roCategory == null || vehicle.vinNumber == null)
    ) {
      return true;
    }

    return false;
  }, [state]);

  const shouldDisplayVignetteTypeWarning = () => {
    const {
      vignette: { countryCode, type },
    } = state;

    if (
      ['D1', '2A', 'V1'].includes(type) &&
      ['HU', 'SI', 'RO'].includes(countryCode)
    ) {
      return true;
    }

    return false;
  };

  const shouldDisplayRoVignetteWarning = () => {
    const {
      vignette: { countryCode },
    } = state;
    if (countryCode !== 'RO') {
      return false;
    }

    const { vehicle } = state;
    if (vehicle.roCategory != null && vehicle.vinNumber != null) {
      return false;
    }

    return true;
  };

  const { vehicle, availableCountries, availableVignettes, vignette } = state;
  const selectedCountryCode = vignette.countryCode;
  const selectedVignetteCode =
    vignette.countryCode && vignette.type && vignette.period
      ? `${vignette.countryCode}-${vignette.type}-${vignette.period}`
      : null;

  const isHu1YVignette = vignette.countryCode === 'HU' && vignette.period === '1Y';

  return (
    <React.Fragment>
      <Dialog open={state.isAddVignetteDialogOpen} onClose={onClose}>
        <DialogTitle>Dodaj winietę do koszyka</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Wprowadź dane niezbędne do wykupienia winiety.
          </DialogContentText>
          <Stack spacing={2}>
            <FormControl required fullWidth variant='standard' size='small'>
              <label
                htmlFor='vehicle'
                style={{
                  color: 'rgba(0, 0, 0, 0.38)',
                  fontSize: '0.75rem',
                  lineHeight: '1.4375em',
                  letterSpacing: '0.00938em',
                }}
              >
                Numer rejestracyjny pojazdu*
              </label>
              <div id='vehicle'>
                <Plate
                  country={vehicle.registrationCountry}
                  number={vehicle.registrationNumber}
                />
              </div>
            </FormControl>
            <TextField
              margin='dense'
              id='vehicle-vin-number-text-field'
              label='Numer VIN pojazdu'
              type='text'
              fullWidth
              variant='standard'
              size='small'
              disabled
              value={vehicle.vinNumber || 'brak'}
            />
            <FormControl required fullWidth variant='standard' size='small'>
              <InputLabel id='country-select'>Winieta do kraju</InputLabel>
              <Select
                labelId='country-label'
                id='country-select'
                label='Winieta do kraju'
                value={selectedCountryCode}
                onChange={handleVignetteCountryChange}
              >
                {availableCountries.map((aC) => {
                  return (
                    <MenuItem key={aC.countryCode} value={aC.countryCode}>
                      <div className='AddVignetteDialog__CountrySelectOption'>
                        <Flag
                          key={aC.countryCode}
                          countryCode={aC.countryCode}
                          style={{ marginRight: '10px' }}
                        />
                        {aC.label}
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl required fullWidth variant='standard' size='small'>
              {selectedVignetteCode && (
                <>
                  <InputLabel id='vignette-label'>Typ winiety</InputLabel>
                  <Select
                    labelId='vignette-label'
                    id='vignette-select'
                    label='Okres obowiązywania winiety'
                    value={selectedVignetteCode}
                    onChange={handleVignetteTypeChange}
                  >
                    {availableVignettes.map((aV) => {
                      return (
                        <MenuItem key={aV.code} value={aV.code}>
                          {aV.label} {`(${displayPrice(aV.grossPrice)})`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </>
              )}
              {shouldDisplayVignetteTypeWarning() && (
                <Alert sx={{ mt: 1, maxWidth: '360px' }} severity='warning'>
                  Wykryliśmy, że próbują Państwo wykupić winietę dla pojazdu
                  osobowego. Jeśli rzeczywiście tak jest, można kontynuować,
                  jeśli nie - proszę najpierw poprawić dane pojazdu w zakładce
                  Ustawienia w sekcji Moja Flota!
                </Alert>
              )}
              {shouldDisplayRoVignetteWarning() && (
                <Alert sx={{ mt: 1, maxWidth: '360px' }} severity='error'>
                  Dodanie do koszyka winiety do Rumunii dla tego pojazdu nie
                  jest możliwe, ponieważ pojazd nie ma zdefiniowanej kategorii
                  pojazdu w Rumunii lub numeru VIN pojazdu. Aby odblokować
                  winiety do Rumunii dla tego pojazdu, proszę najpierw uzupełnić
                  dane pojazdu w zakładce Ustawienia w sekcji Moja Flota!.
                </Alert>
              )}
            </FormControl>
            <FormControl style={{ paddingTop: '1rem' }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={pl}
              >
                <DatePicker
                  label='Winieta ważna od*'
                  format='dd-MM-yyyy'
                  minDate={new Date()}
                  value={vignette.startDate}
                  disabled={isHu1YVignette}
                  onChange={(newStartDate) =>
                    handleStartDateValueChange(newStartDate)
                  }
                  onError={(error) => handleStartDateErrorChange(error)}
                />
              </LocalizationProvider>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: '16px 24px' }}>
          <FleetButton onClick={onClose}>Anuluj</FleetButton>
          <FleetButton
            onClick={handleSubmitButton}
            disabled={isSubmitButtonDisabled}
          >
            Dodaj do koszyka
          </FleetButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
